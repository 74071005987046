import React, { useEffect } from "react"
import { Link } from "gatsby"
import { connect } from "react-redux"
import { fetchOrganisations } from "../../state"
import { Layout } from "../../components/Layout"
import { OrganisationsSection } from "../../style/organisations"
import { OrganisationTable } from "../../components/OrganisationTable"
import { BallBeat } from "react-pure-loaders"

function OrganisationsContainer({
  organisationsData,
  fetchOrganisations,
  user = {},
}) {
  useEffect(() => {
    // console.log("fetchOrganisations being called")
    fetchOrganisations(user)
  }, [fetchOrganisations])

  return (
    <Layout>
      <OrganisationsSection
        style={
          {
            // display: "flex",
            // flexDirection: "column",
          }
        }
      >
        <h1 style={{}}>Organisations</h1>
        <br />
        <br />
        <Link
          to="/organisations/neworganisation"
          style={{
            textDecoration: "none",
            padding: "0.5rem 1rem",
            // margin: "1rem 0",
            marginBottom: "3rem",
            // marginRight: "3rem",
            borderRadius: "5rem",
            width: "fit-content",
            backgroundColor: "lightblue",
          }}
        >
          Add New Organisation
        </Link>
        {organisationsData.loading ? (
          <>
            <br />
            <br />
            <br />
            <h4>
              Loading organisations{" "}
              <span>
                <BallBeat
                  color={"#123abc"}
                  loading={organisationsData.loading}
                />
              </span>{" "}
            </h4>
          </>
        ) : organisationsData.error ? (
          <>
            <br />
            <br />
            <br />

            <h4>Error ⚠️ Fetching Organisations</h4>
            <br />
            <p style={{ color: "grey" }}>{organisationsData.error}</p>
            <br />
            <br />
            {/* <Link
              to="/organisations/neworganisation"
              style={{
                textDecoration: "none",
                padding: "1rem",
                borderRadius: "5rem",
                width: "fit-content",
                backgroundColor: "lightblue",
              }}
            >
              Add Organisation
            </Link> */}
          </>
        ) : organisationsData.organisations.length === 0 ? (
          <>
            <br />
            <br />
            <h4>No organisations found</h4>
          </>
        ) : (
          <>
            {/* <h1>Organisations </h1> */}
            <br />
            <br />
            <OrganisationTable
              organisations={organisationsData.organisations}
            />
          </>
        )}
      </OrganisationsSection>
    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {
    organisationsData: state.organisations,
    user: state.login.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrganisations: (user) => dispatch(fetchOrganisations(user)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationsContainer)
