import React from "react"
import { Table } from "./Table"
import { TableStyles } from "../style/globalStyles"
import Moment from "moment"

export const OrganisationTable = ({ organisations }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Organisation ID",
        accessor: "organizationId",
      },
      {
        Header: "Organisation",
        accessor: "organizationName",
      },

      {
        Header: "Type",
        accessor: "organizationType",
      },
      {
        Header: "City",
        accessor: "organizationCity",
      },
      {
        Header: "Discount",
        accessor: "organizationDiscount",
      },
      {
        Header: "Start Date",
        accessor: (organisation) =>
          organisation.startDate
            ? Moment(organisation.startDate).format("DD MMM YYYY")
            : "NA",
      },
      {
        Header: "End Date",
        accessor: (organisation) =>
          organisation.endDate
            ? Moment(organisation.endDate).format("DD MMM YYYY")
            : "NA",
      },
      // {
      //   Header: "Active",
      //   accessor: (isActive) => {
      //     return isActive.isActive ? "Active   " : "Not Active    "
      //   },
      // },
    ],
    []
  )

  var data = React.useMemo(() => organisations, [])

  var tableRender = (
    <Table
      // Pass the page url here to navigate to onClick
      path={"/organisations/organisation"}
      columns={columns}
      data={data}
    />
  )

  return <TableStyles>{organisations ? tableRender : " "}</TableStyles>
}
